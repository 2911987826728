import * as React from "react"
import Layout from "../components/layout"
import { BaseImage, DuoImage } from "../components/photos"
import * as styles from "./index.module.scss"

import stupa from "../images/nepal/kathmandu/stupa.png"
import flags from "../images/nepal/kathmandu/flags.png"
import monk from "../images/nepal/kathmandu/monk.png"
import hills from "../images/nepal/kathmandu/hills.png"
import stupamonks from "../images/nepal/kathmandu/stupamonks.png"
import temple from "../images/nepal/kathmandu/temple.png"
import street from "../images/nepal/kathmandu/street.png"
import city from "../images/nepal/kathmandu/city.png"
import birds from "../images/nepal/kathmandu/birds.png"
import cremation from "../images/nepal/kathmandu/cremation.png"
import cleaning from "../images/nepal/kathmandu/cleaning.png"
import smoke from "../images/nepal/kathmandu/smoke.png"
import river from "../images/nepal/kathmandu/river.png"
import temple2 from "../images/nepal/kathmandu/temple2.png"

import plane from "../images/nepal/himalayas/plane.png"
import mist from "../images/nepal/himalayas/mist.png"
import hotel from "../images/nepal/himalayas/hotel.png"
import edoras from "../images/nepal/himalayas/edoras.png"
import everest from "../images/nepal/himalayas/everest.png"
import bell from "../images/nepal/monastery/bell.png"
import dog from "../images/nepal/monastery/dog.png"
import monks from "../images/nepal/monastery/monks.png"
import mountains from "../images/nepal/monastery/mountains.png"
import decor from "../images/nepal/monastery/decor.png"
import shadow from "../images/nepal/monastery/shadow.png"
import monkeys from "../images/nepal/monastery/monkeys.png"
import shop from "../images/nepal/monastery/shop.png"

import stupa2 from "../images/nepal/pokhara/stupa.png"
import city2 from "../images/nepal/pokhara/city.png"
import island from "../images/nepal/pokhara/island.png"
import mist2 from "../images/nepal/pokhara/mist.png"
import farmer from "../images/nepal/pokhara/farmer.png"
import ferris from "../images/nepal/pokhara/ferris.png"
import fire from "../images/nepal/pokhara/fire.png"
import music from "../images/nepal/pokhara/music.png"

class Nepal extends React.Component {
    render() {
        return (
            <Layout title="Shots from Nepal">
                <div className={styles.photoBlog}>
                    <p>
                        <a href="/">
                            Home
                        </a>
                        <h3>Shots from Nepal</h3>
                        <div>Various locations in Kathmandu, the Himalayas, and Pokhara - December 2022</div>
                    </p>
                    <BaseImage imgSrc={stupa} />
                    <DuoImage imgSrc={flags} imgSrc2={monk} />
                    <BaseImage imgSrc={hills} />
                    <DuoImage imgSrc={stupamonks} imgSrc2={temple} />
                    <BaseImage imgSrc={street} />
                    <BaseImage imgSrc={city} />
                    <BaseImage imgSrc={temple2} />
                    <BaseImage imgSrc={cremation} />
                    <BaseImage imgSrc={birds} />
                    <DuoImage imgSrc={cleaning} imgSrc2={smoke} />
                    <BaseImage imgSrc={river} />
                    <BaseImage imgSrc={monks} />
                    <DuoImage imgSrc={decor} imgSrc2={dog} />
                    <BaseImage imgSrc={shadow} />
                    <BaseImage imgSrc={shop} />
                    <BaseImage imgSrc={monkeys} />
                    <BaseImage imgSrc={mountains} />
                    <BaseImage imgSrc={bell} />
                    <BaseImage imgSrc={hotel} />
                    <BaseImage imgSrc={edoras} />
                    <BaseImage imgSrc={everest} />
                    <BaseImage imgSrc={mist} />
                    <BaseImage imgSrc={plane} />
                    <BaseImage imgSrc={mist2} />
                    <BaseImage imgSrc={city2} />
                    <DuoImage imgSrc={island} imgSrc2={ferris} />
                    <BaseImage imgSrc={farmer} />
                    <DuoImage imgSrc={music} imgSrc2={fire} />
                    <BaseImage imgSrc={stupa2} />
                </div>
            </Layout>
        )
    }
}

export default Nepal
